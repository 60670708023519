import { useRuntimeConfig } from '#app'
import { combineUrlWithParams } from '~/utils/httpUtils'

// 對話標籤控制器
const { useCustomFetch } = useFetchBase()

const base = '/api/message'

const module = '/conversationTags'

const path = base + module
// request(REQ) Api  --------------------------------
const getConversationTags = path + '/q' // 獲取對話標籤列表

const addConversationTag = path // 添加對話標籤

const conversationTag = path + '/{0}' // 編輯對話標籤

/**
 * 獲取對話標籤列表
 * @param {Object} params 查詢參數
 * @param {Object} callback 回調函數
 * @returns 分頁數據
 */
const getConversationTagsFn = (params, callback) => { // 獲取對話標籤列表
  // check params.word not undefined
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + combineUrlWithParams(getConversationTags, params)
  return useCustomFetch(api, {
    method: 'GET',
    onResponse: callback
  })
}
/**
 * 添加對話標籤
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
const addConversationTagFn = (formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  return useCustomFetch(apiBaseUrl + addConversationTag, {
    method: 'POST',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 編輯對話標籤
 * @param {String} id
 * @param {Object} formData 表單資料
 * @param {Object} callback 回調函數
 * @returns
 */
const editConversationTagFn = (id, formData, callback) => {
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(conversationTag, id)
  return useCustomFetch(api, {
    method: 'PUT',
    body: JSON.stringify(formData),
    onResponse: callback
  })
}

/**
 * 刪除對話標籤
 * @param {String} id 對話標籤id
 * @param {Object} callback 回掉函數
 * @returns
 */
const deleteConversationTagFn = (id, callback) => { // 刪除對話標籤
  const config = useRuntimeConfig()
  const apiBaseUrl = `${config.public.apiBaseUrl}`
  const api = apiBaseUrl + stringFormat(conversationTag, id)
  return useCustomFetch(api, {
    method: 'DELETE',
    onResponse: callback
  })
}

export {

  getConversationTagsFn,
  addConversationTagFn,
  editConversationTagFn,
  deleteConversationTagFn
}
